import request from '@/utils/request'

export function getOneByAdIdApi(data) {
    return request({
        url: '/advert/psidTotalConfig/getOneByAdId',
        method: 'post',
        data
    })
}

export function saveApi(data) {
    return request({
        url: '/advert/psidTotalConfig/save',
        method: 'post',
        data
    })
}




