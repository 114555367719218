<template>

  <div>

    <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

      <el-form-item label="广告类型" prop="adTypeName">
        <el-input v-model="temp.adTypeName" disabled="disabled"  />
      </el-form-item>


      <el-form-item label="总开关" prop="onOff">
        <el-select v-model="temp.onOff" placeholder="总开关">
          <el-option
              v-for="item in onOffOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="深度模式" prop="deepOnOff">
        <el-select v-model="temp.deepOnOff" placeholder="总开关">
          <el-option
              v-for="item in deOnOffOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="广告对象" prop="adNames">

        <el-select v-model="temp.adNames" multiple placeholder="广告对象">
          <el-option
              v-for="item in adOptions"
              :key="item.name"
              :label="item.nameZh"
              :value="item.name">
          </el-option>
        </el-select>

      </el-form-item>

      <el-form-item label="总展示数" prop="showTotal">
        <el-input v-model="temp.showTotal" />
      </el-form-item>

      <el-form-item label="总点击数" prop="clickTotal">
        <el-input v-model="temp.clickTotal" />
      </el-form-item>

      <el-form-item label="埋点开关" prop="openLog">

        <el-select v-model="temp.openLog" placeholder="埋点开关">
          <el-option
              v-for="item in openLogOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>

        </el-select>

      </el-form-item>

      <el-form-item label="更改类型" prop="changeAtype">

        <el-select v-model="temp.changeAtype" placeholder="更改类型">
          <el-option
              v-for="item in changeAtypeOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
        </el-select>

      </el-form-item>


      <el-form-item label="开屏时间(毫秒)" prop="splTime">
        <el-input v-model="temp.splTime" />
      </el-form-item>

      <el-form-item label="自动展示模式" prop="auShow">

        <el-select v-model="temp.auShow" placeholder="请选择">
          <el-option
              v-for="item in auShowOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
        </el-select>

      </el-form-item>

      <el-form-item label="回调URL" prop="verifyUrl" v-if="temp.openVerify == 1">
        <el-input v-model="temp.verifyUrl" />
      </el-form-item>

      <el-form-item label="服务端校验">
        <el-radio-group v-model="temp.openVerify" @change="changeRadio">
          <el-radio :label="2">不需要服务端判断</el-radio>
          <el-radio :label="1">需要服务端判断</el-radio>
        </el-radio-group>
      </el-form-item>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="canelTotalConfig">
        取消
      </el-button>
      <el-button type="primary" @click="saveTotalConfig" >
        保存
      </el-button>
    </div>
  </div>

</template>

<script>
import { getOneByAdIdApi,saveApi } from '@/api/pdTotalConfig'

import { adslistApi } from '@/api/adObjects'
export default {
  name: "adsTotalConfig",
  props: ['row'],
  data(){

    return{

      rules: {
        onOff: [{ required: true, message: '不能为空', trigger: 'blur' }],
        adNames: [{ required: true, message: '不能为空', trigger: 'blur' }],
        showTotal: [{ required: true, message: '不能为空', trigger: 'blur' }],
        clickTotal: [{ required: true, message: '不能为空', trigger: 'blur' }],
        openLog: [{ required: true, message: '不能为空', trigger: 'blur' }],
        verifyUrl: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      temp: {
        adTypeName:'',
        id: undefined,
        phoneUserId:'',
        adId: '',
        pd:'',
        onOff: '',
        deepOnOff: '',
        adNames: [],
        showTotal: '',
        clickTotal: '',
        openLog: '',
        changeAtype: '',
        splTime:'',
        auShow:'',
        openVerify: 2,
        verifyUrl:''

      },
      onOffOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],

      deOnOffOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      auShowOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      openLogOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      changeAtypeOptions:[{
        id: 1,
        label: '默认'
      },{
        id: 2,
        label: '定制'
      }],
      adOptions:[

      ]
    }
  },
  created() {
    this.getData();
    this.getAdsList();

  },
  watch:{
    row: function (n ,o) {
      console.info(n,o)
      this.temp= {
        id: undefined,
        phoneUserId:'',
        adId: '',
        pd:'',
        onOff: '',
        deepOnOff: '',
        adNames: [],
        showTotal: '',
        clickTotal: '',
        openLog: '',
        changeAtype: '',
        splTime:'',
        auShow:'',
        openVerify: 2,
        verifyUrl:''

      }
      this.getData();

    }
  },
  methods:{
    changeRadio() {
      // console.log(111)
      if(this.temp.openVerify == 2) {
        this.temp.verifyUrl = ''
      }
    },
    getAdsList(){
      adslistApi().then(response => {
        if(response.body){
          this.adOptions = response.body.records
        }
      })
    },
    getData(){
      this.temp.adTypeName = this.row.adTypeName
      getOneByAdIdApi({"adId":this.row.adId,"pd":this.row.pd}).then(response => {
        if(response.body){
          let datas = response.body
          let body = {}
          for (const i in datas) {
              body[i] = datas[i]
          }
          this.temp = {...body}
          // this.temp = response.body
          this.temp.adNames = JSON.parse(this.temp.adNames)
          this.ads = this.temp.adNames
           this.temp.openVerify = this.temp.openVerify ? this.temp.openVerify : 2
          this.temp.adTypeName = this.row.adTypeName
          console.info(this.temp)
        }
      })
    },
    canelTotalConfig(){
      this.$emit("dialogTotalConfigVisible", false)
    },
    saveTotalConfig(){
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.phoneUserId = this.row.id;
          this.temp.adId = this.row.adId;
          this.temp.pd = this.row.pd;
          var temtotal =  this.temp;
          temtotal.adNames = JSON.stringify(this.temp.adNames);
          saveApi(temtotal).then(() => {
            this.$notify({
              title: 'Success',
              message: '保存成功',
              type: 'success',
              duration: 2000
            })
            this.$emit("dialogTotalConfigVisible", false)
          })

          this.temp.adNames = JSON.parse(this.temp.adNames)
        }

      })

    }
  }
}
</script>
<style scoped>
.dialog-footer{
  margin-left: 520px;
}
</style>
