<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>配置</el-breadcrumb-item>
      <el-breadcrumb-item>pd</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
<!--    <el-row>-->
<!--      <el-button type="primary" @click="handleCreate">添加</el-button>-->
<!--    </el-row>-->
<!--    总配置 B-->
    <el-dialog title="总配置" :visible.sync="dialogFormTotalConfig">
        <pdTotalConfig :row="pdTotalsetTemp"  @dialogTotalConfigVisible="totalConfigVisible" />
    </el-dialog>
<!--    总配置 E-->
    <!--    明细配置 B-->
    <el-dialog title="明细配置" :visible.sync="dialogFormDetailConfig" style="width:2200px;margin-left: -400px" >
       <pdDetailConfig :row="pdDetailsetTemp"  />
    </el-dialog>
    <!--    明细配置 E-->

<!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="应用" prop="appId">
          <el-select v-model="temp.appId" v-bind:disabled="dialogStatus === 'update'"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开启单点运营" prop="attribute">
          <el-select v-model="temp.openSingle" placeholder="是否开启单点精准运营">
            <el-option
                v-for="item in attributeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="sdk版本" prop="versionName">
          <el-input v-model="temp.versionName" />
        </el-form-item>

        <el-form-item label="签名校验" prop="sign">
          <el-input v-model="temp.sign" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">

      <el-table-column fixed fit align="center" type="index" min-width="20" />

      <el-table-column prop="adName" label="广告位名称" min-width="50" />

      <el-table-column prop="model" label="机型" min-width="50" />

      <el-table-column prop="sysType" label="系统类型" min-width="50" />

      <el-table-column prop="adTypeName" label="广告类型" min-width="50" />

      <el-table-column label="配置" align="center" min-width="70" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleTotalSet(scope.$index, scope.row)">总配置</el-button>
          <el-button
              size="mini"
              @click="handleDetailedSet(scope.$index, scope.row)">明细配置</el-button>
        </template>
      </el-table-column>

<!--      <el-table-column label="操作" align="center" min-width="70" >-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--              size="mini"-->
<!--              type="primary"-->
<!--              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>-->
<!--          <el-button-->
<!--              size="mini"-->
<!--              type="danger"-->
<!--              @click="handleDelete(scope.$index, scope.row)">删除</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
<!--    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />-->
    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
    </el-pagination>
    <div style="height: 30px;" />
    </div>



  </div>
</template>

<script>
import { listApi,addApi,updateApi,delApi } from '@/api/pd'
import { listAllApi } from '@/api/apps'

import pdTotalConfig from "@/components/pdTotalConfig";
import pdDetailConfig from "@/components/pdDetailConfig";
export default {
  name: "ads",
  components: {pdDetailConfig, pdTotalConfig },
  inject: ['load','closeLoad'],
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogFormDetailConfig:false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        appId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        openSingle: [{ required: true, message: '不能为空', trigger: 'blur' }],
        versionName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        sign: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      temp: {
        id: undefined,
        appId: '',
        openSingle: '',
        versionName: '',
        sign: '',

      },
      tableData: [],
      appOptions:[],
      attributeOptions:[{
        id: 1,
        label: '开启'
      },{
        id: 2,
        label: '关闭'
      }],
      pdTotalsetTemp:{

      },
      pdDetailsetTemp:{

      }
    }
  },
  created() {
    this.getList();
    this.getInitOptions();
  },
  methods:{
    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
        this.appOptions = response.body.records
      })
    },
    getList() {
      this.load()
      this.listLoading = true
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
        // Just to simulate the time of the request
        this.closeLoad()
        setTimeout(() => {
          this.listLoading = false
        }, 1.5 * 1000)
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log(this.temp);
          addApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        customerId: '',
        name: '',
        pkg: '',
        versionName: '',
        type: '',
        attribute: '',
      }
    },
    handleContant(index, row) {
      console.info(index,row);
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          updateApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },

    handleTotalSet(index, row){

      this.dialogFormTotalConfig = true
      this.pdTotalsetTemp = row;

    },
    handleDetailedSet(index, row){
      console.log(index, row);

      this.dialogFormDetailConfig = true
      this.pdDetailsetTemp = row;

    },
    totalConfigVisible(v){
      this.dialogFormTotalConfig = v
    }


  }
}

</script>

<style scoped>


</style>
