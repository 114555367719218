import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/phoneUser/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/phoneUser/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/phoneUser/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/phoneUser/del',
        method: 'post',
        data
    })
}

export function todayListByadIdApi(data) {
    return request({
        url: '/advert/phoneUser/getTodayByAdId',
        method: 'post',
        data
    })
}
export function listByadIdApi(data) {
    return request({
        url: '/advert/phoneUser/getListByAdId',
        method: 'post',
        data
    })
}
