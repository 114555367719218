import request from '@/utils/request'


export function saveOrder(data) {
    return request({
        url: '/advert/psidDetailConfig/saveOrder',
        method: 'post',
        data
    })
}

export function adsort(data) {
    return request({
        url: '/advert/psidDetailConfig/adsort',
        method: 'post',
        data
    })
}

export function listApi(data) {
    return request({
        url: '/advert/psidDetailConfig/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/psidDetailConfig/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/psidDetailConfig/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/psidDetailConfig/del',
        method: 'post',
        data
    })
}



